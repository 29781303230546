<template>
    <el-col :span="12" style="text-align: left; padding-top: 20px">
        <h3 style="font-weight: bold; text-align: left; padding-left: 80px"> Tracking Page </h3>
    </el-col>
    <el-col :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px; font-size: 15px;">
      <br/>
      <p>To register a tag, click on the Register Tag button on the main dashboard page.</p>
      <el-image style="width: 139px; height: 67px" src="/DragonKitRegisteringTag1.png" fit="cover" />
      <br/>
      <p>Fill out the form using the tag MAC address, your preferred name and object/color.</p>
      <el-image style="width: 326px; height: 235px" src="/DragonKitRegisteringTag2.png" fit="cover" />
      <br/><br/>
      <p>Once you click Add, the registered tag will be added to your list.</p>
    </el-col>
</template>