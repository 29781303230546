<template>
    <el-col :span="12" style="text-align: left; padding-top: 20px">
        <h3 style="font-weight: bold; text-align: left; padding-left: 80px"> Required Materials </h3>
    </el-col>
    <el-col :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px; font-size: 15px;">
      <br/>
      <ul>
        <li>POE Switch</li>
        <br/>
        <li>Ethernet Cable (15 ft minimum Length)</li>
        <br/>
        <li>Computer/Laptop</li>
        <br/>
        <li>Access to Internet</li>
        <br/>
        <li>Tape or laser Measure (<el-link href="https://www.bosch-professional.com/ma/en/products/glm-20-0601072EG0" target="_blank" type="primary">https://www.bosch-professional.com/ma/en/products/glm-20-0601072EG0</el-link>)</li>
        <br/>
        <li>Velcro adhesive tape (<el-link href="https://www.command.com/3M/en_US/p/d/cobnaw017069/" target="_blank" type="primary">https://www.command.com/3M/en_US/p/d/cobnaw017069/</el-link>)</li>
      </ul> 
    </el-col>
</template>