<template>
    <el-col :span="12" style="text-align: left; padding-top: 20px">
        <h3 style="font-weight: bold; text-align: left; padding-left: 80px"> Basic Setup and Registration </h3>
    </el-col>
    <el-col :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px; font-size: 15px;">
      <br/>
      <ol>
        <li>Define an area for your study, ideal space for evaluation is 3-meter height and a minimum area of 100-meter square with Argus One Locator at the center of the layout (10x10x3).</li>
        <br/>
        <li>Install the mounting bracket at the desired ceiling location. For temporary mounting, we recommend using Velcro adhesive tape to secure the bracket.</li>
        <br/>
        <el-image style="width: 321px; height: 122px" src="/DragonKitBasicInstallation1.png" fit="cover" />
        <br/><br/>
        <li>Align the locator to the mounting bracket and slide back locator to snap into position.</li>
        <br/>
        <el-image style="width: 330px; height: 149px" src="/DragonKitBasicInstallation2.png" fit="cover" />
        <br/><br/>
        <li>Provide at least a meter of cable service, where the ethernet cable does not hang near the locator.</li>
        <br/>
        <el-image style="width: 353px; height: 135px" src="/DragonKitBasicInstallation3.png" fit="cover" />
      </ol> 
    </el-col>
</template>