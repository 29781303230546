<template>
    <el-col :span="12" style="text-align: left; padding-top: 20px">
        <h3 style="font-weight: bold; text-align: left; padding-left: 80px"> Tracking Page </h3>
    </el-col>
    <el-col :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px; font-size: 15px;">
      <br/>
      <p><b>Start Tracking:</b>To start showing the real time location and movements of the tags on the tracking page.</p>
      <el-image style="width: 120px; height: 120px" src="/DragonKitTrackingPage1.png" fit="cover" />
      <br/><br/>
      <p><b>Stop Tracking:</b>To stop showing the real time location and movements of the tags on the tracking page.</p>
      <el-image style="width: 120px; height: 120px" src="/DragonKitTrackingPage2.png" fit="cover" />
      <br/><br/>
      <p><b>Network Details:</b>To go back to the network details and main dashboard page.</p>
      <el-image style="width: 120px; height: 120px" src="/DragonKitTrackingPage3.png" fit="cover" />
      <br/><br/>
      <p><b>Display Options:</b>On the top right side of the page click on the Display Options to access the menu:</p>
      <el-image style="width: 120px; height: 80px" src="/DragonKitTrackingPage4.png" fit="cover" />
      <br/>
      <el-image style="width: 363px; height: 386px" src="/DragonKitTrackingPage5.png" fit="cover" />
      <br/><br/>
      <p><b>Display Tags:</b>Choose between showing all the tags in the test area or the registered ones only.</p>
      <p><b>Activate Ray:</b>Activate or deactivate the ray for better visual understanding.</p>
      <p><b>Field of View:</b>Show or Hide the field of view for the locators (FOV for each locator can be customized in the Setup menu).</p>
      <p><b>Uncertainty Radius:</b>Show or Hide the uncertainty radius around the tags.  </p>
      <p><b>Grid:</b>Turning the background grid On or Off based on preference.</p>
      <p><b>Tag Label:</b>Showing or hiding the tag labels if any. </p>
      <p><b>Last Known:</b>If the tag goes out of range or loses connection for any reason, turning on this option always shows the last know location of the tag.</p>
    </el-col>
</template>