<template>
    <el-col :span="12" style="text-align: left; padding-top: 20px">
        <h3 style="font-weight: bold; text-align: left; padding-left: 80px"> Changing the Height </h3>
    </el-col>
    <el-col :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px; font-size: 15px;">
      <br/>
      <p>To change the height of the tag, click on the Settings.</p>
      <el-image style="width: 100px; height: 41px" src="/DragonKitChangingHeight1.png" fit="cover" />
      <br/>
      <p>From the menu select the RTLS Settings.</p>
      <el-image style="width: 723px; height: 225px" src="/DragonKitChangingHeight2.png" fit="cover" />
      <br/><br/>
      <p>From the first line, under height, change the value to your preferred test height in meters.</p>
      <el-image style="width: 625px; height: 79px" src="/DragonKitChangingHeight3.png" fit="cover" />
    </el-col>
</template>