<template>
    <el-col :span="12" style="text-align: left; padding-top: 20px">
        <h3 style="font-weight: bold; text-align: left; padding-left: 80px"> Basic Setup and Registration </h3>
    </el-col>
    <el-col :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px; font-size: 15px;">
      <br/>
      <ol>
        <li>Connect Argus One to the internet via POE Switch</li>
        <el-image style="width: 621px; height: 149px" src="/DragonKitBasicSetup1.png" fit="cover" />
        <br/><br/>
        <li>Go to <el-link href="https://iosea-rtls-lab.net/" target="_blank" type="primary">https://iosea-rtls-lab.net/</el-link> or scan QR code available on the packaging to open an account and register your unit. Serial No. and Mac Address are at the back of the unit.</li>
        <br/>
        <el-image style="width: 508px; height: 717px" src="/DragonKitBasicSetup2.png" fit="cover" />
        <li>Upon completion of registration, your locator is now online.</li>
      </ol> 
    </el-col>
</template>