<template>
    <el-col :span="12" style="text-align: left; padding-top: 20px">
        <h3 style="font-weight: bold; text-align: left; padding-left: 80px"> Basic Setup to create a Working Space </h3>
    </el-col>
    <el-col :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px; font-size: 15px;">
      <br/>
      <ol>
        <li>Go to the dashboard: <el-link href="https://iosea-rtls-lab.net/" target="_blank" type="primary">https://iosea-rtls-lab.net/</el-link> and login to your account.</li>
        <br/>
        <li>Add a new working space. For basic evaluation, we recommend starting with a working space of 10x10x5 space.</li>
        <br/>
        <el-image style="width: 570px; height: 228px" src="/DragonKitCreateWorkingSpace1.png" fit="cover" />
        <br/><br/>
        <li>Select your new space to add the locator. </li>
        <br/>
        <el-image style="width: 562px; height: 124px" src="/DragonKitCreateWorkingSpace2.png" fit="cover" />
        <br/><br/>
        <li>Click Action and Select Setup. For basic evaluation, we recommend positioning the locator at the center of your working space with your measured height (for best performance the height should be at least 3 meter)</li>
        <br/>
        <ul>
          <li>Locator Position</li>
          <el-image style="width: 684px; height: 107px" src="/DragonKitCreateWorkingSpace3.png" fit="cover" />
          <br/><br/>
          <li>Locator Orientation</li>
          <el-image style="width: 684px; height: 118px" src="/DragonKitCreateWorkingSpace4.png" fit="cover" />
          <br/><br/>
        </ul>
        <el-image style="width: 636px; height: 166px" src="/DragonKitCreateWorkingSpace5.png" fit="cover" />
        <br/><br/> 
        <p>Note: The LED should be blue.</p>
        <li>Under settings, select appropriate testing.</li>
        <br/>
        <el-image style="width: 483px; height: 111px" src="/DragonKitCreateWorkingSpace6.png" fit="cover" />
        <br/><br/>
        <p>Note: We are now ready for basic testing</p>
        <li>Turn the Panasonic Jaguar Tag to On and the blue LED on the Argus One Locator should be blinking.</li>
      </ol> 
    </el-col>
</template>