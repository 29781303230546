<template>
    <el-col :span="12" style="text-align: left; padding-top: 20px">
        <h3 style="font-weight: bold; text-align: left; padding-left: 80px"> Dragon Promo Kit Contents </h3>
    </el-col>
    <el-col :span="16" style="text-align: left; padding-top: 10px; padding-left: 80px; font-size: 15px;">
      <br/>
      <ul>
        <li>IOSEA’s Argus One Locator with Mounting Bracket</li>
        <br/>
        <li>Panasonic Jaguar Tag (based on PAN1781 Module)</li>
        <br/>
        <li>60 Day License to IOSEA RTLS Software</li>
        <br/>
        <el-image style="width: 459px; height: 279px" src="/DragonKitContents.png" fit="cover" />
      </ul> 
    </el-col>
</template>