<template>
  <el-row align="top">
    <el-col :span="5">
      <el-col :span="10">
        <div class="demo-image">
          <el-image style="width: 100px; height: 100px" src="/ioseadoc.png" fit="contain" />
        </div>
      </el-col>
    </el-col>
    <el-col :span="19">
      <div v-if="user">
        <ul class="my-auto text-right" style="list-style-type: none; padding-top: 30px; padding-right: 30px;">
          <li>
            <el-dropdown split-button trigger="click" @command="handleCommand">
              <span class="user-email">{{ user.email }}</span>
              <template #dropdown>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click="onClickLogout">Logout</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </li>
        </ul>
      </div>
    </el-col>
  </el-row>
  <el-row align="top">
    <el-col :span="2" style="text-align: left;">
    </el-col>
  </el-row>
  <el-row align="top">
    <el-col :span="7">
      <el-scrollbar height="77vh">
        <el-menu
          default-active="1-1"
          class="el-menu-vertical-demo"
          @select="handleSelect"
        >
          <el-sub-menu index="1">
            <template #title>Dragon Promo Kit Quick Start Guide</template>
            <el-menu-item index="1-1">Introduction</el-menu-item>
            <el-menu-item index="1-2">Dragon Promo Kit Contents</el-menu-item>
            <el-menu-item index="1-3">Required Materials</el-menu-item>
            <el-menu-item index="1-4">Basic Setup and Registration</el-menu-item>
            <el-menu-item index="1-5">Basic Installation for Ceiling Mount</el-menu-item>
            <el-menu-item index="1-6">Basic Setup to create a Working Space</el-menu-item>
            <el-menu-item index="1-7">Tracking page</el-menu-item>
            <el-menu-item index="1-8">Registering a Tag</el-menu-item>
            <el-menu-item index="1-9">Changing the Height</el-menu-item>
          </el-sub-menu>
        </el-menu>
      </el-scrollbar>
    </el-col>

    <el-col :span="17">
      <el-scrollbar height="77vh">
        <div style="container-fluid; min-height: 80vh; padding-bottom: 50px;">
          <component :is="selectedComponent"></component>
        </div>
      </el-scrollbar>
    </el-col>
  </el-row>
  <footer class="footer mt-auto py-2">
    <div style="font-size: 14px;">Copyright © 2023 IOSEA Inc. All rights reserved.</div>
    <div>
      <p style="font-size: 13px;">Version 05-05-2024</p>
    </div>
  </footer>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRouter } from "vue-router";
import getUser from "../composables/getUser";
import useLogout from "../composables/useLogout";
import { ArrowDown } from '@element-plus/icons-vue'
import DragonKitIntroduction from "../components/DragonKit/DragonKitIntroduction.vue";
import DragonKitContents from "../components/DragonKit/DragonKitContents.vue";
import DragonKitRequiredMaterials from "../components/DragonKit/DragonKitRequiredMaterials.vue";
import DragonKitBasicSetupAndRegistration from "../components/DragonKit/DragonKitBasicSetupAndRegistration.vue";
import DragonKitBasicInstallationCeilingMount from "../components/DragonKit/DragonKitBasicInstallationCeilingMount.vue";
import DragonKitCreateWorkingSpace from "../components/DragonKit/DragonKitCreateWorkingSpace.vue";
import DragonKitTrackingPage from "../components/DragonKit/DragonKitTrackingPage.vue";
import DragonKitRegisteringTag from "../components/DragonKit/DragonKitRegisteringTag.vue";
import DragonKitChangingHeight from "../components/DragonKit/DragonKitChangingHeight.vue";

export default {
  name: 'Home',
  components: {  },
  setup() {
    const router = useRouter();
    const { user } = getUser();
    const { logout, error } = useLogout();
    const selectedComponent = ref(null);
    const title = ref("");
    const email = ref("");

    // Scroll to the top the page
    window.scrollTo(0,0);

    // Open Introduction when page loads
    selectedComponent.value = DragonKitIntroduction

    const handleSelect = (key, keyPath) => {
      switch (key) {
        case '1-1':
          selectedComponent.value = DragonKitIntroduction;
          break;
        case '1-2':
          selectedComponent.value = DragonKitContents;
          break;
        case '1-3':
          selectedComponent.value = DragonKitRequiredMaterials;
          break;
        case '1-4':
          selectedComponent.value = DragonKitBasicSetupAndRegistration;
          break;
        case '1-5':
          selectedComponent.value = DragonKitBasicInstallationCeilingMount;
          break;
        case '1-6':
          selectedComponent.value = DragonKitCreateWorkingSpace;
          break;
        case '1-7':
          selectedComponent.value = DragonKitTrackingPage;
          break;
        case '1-8':
          selectedComponent.value = DragonKitRegisteringTag;
          break;
        case '1-9':
          selectedComponent.value = DragonKitChangingHeight;
          break;
        default:
          selectedComponent.value = null;
      }
    }

    const onClickLogout = async () => {
      await logout();
      if (error.value) {
        console.log("Error - Logout Failed")
      }
    };

    watch(user, () => {
      if (!user.value) {
        router.push({ name: "SignIn" });
      }
    });

    onMounted(() => {
      
    })

    return {
      onClickLogout,
      user,
      email,
      title,
      ArrowDown,
      handleSelect,
      selectedComponent
    }
  }
}
</script>

<style scoped>
.demo-image .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  display: inline-block;
  width: 20%;
  box-sizing: border-box;
  vertical-align: top;
}
.demo-image .block:last-child {
  border-right: none;
}
.demo-image .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}
a.dropdown-toggle {
   border: none;
   outline: none;
   text-decoration: none;
   color: black;
}
.dropdown-menu {
    height: 45px !important;
    font-size: 14px;
    color: #00d1fd;
}
</style>
